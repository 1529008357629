@import "~@flixbus/honeycomb-react/dist/scss/honeycomb-helpers.scss";


.backgroudColor{
    background-color: cssvar(grayscale-10-color);
}

.headingContainer{
    margin-top: cssvar(spacing-6);
}
