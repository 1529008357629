@import "~@flixbus/honeycomb-react/dist/scss/honeycomb-helpers.scss";

.backgroudColor {
  background-color: cssvar(grayscale-10-color);
}

.pageContainer {
  padding-top: cssvar(spacing-6);
  padding-left: 12%;
  padding-right: 12%;
  padding-bottom: cssvar(spacing-12);
}
