@import "~@flixbus/honeycomb-react/dist/scss/honeycomb-helpers.scss";

$content-start-size: 230px;
$overlay-size: 70px;

.backgroundImage{
    background-image: url('https://media.akamai.odsp.cdn.office.net/northeurope1-mediap.svc.ms/transform/thumbnail?provider=url&inputFormat=jpg&docid=https%3A%2F%2Fcdn.hubblecontent.osi.office.net%2Fm365content%2Fpublish%2F0c512a74-792c-4b9c-878f-25859df1abe9%2F914272382.jpg&w=960');
    background-size: cover;
}

.mainHeading {
    color: white;
    font-size: 34px
}

.headingContainer{
    margin-top: $content-start-size;
}

.quoteContaioner{
    margin-bottom: -$overlay-size;
    margin-top: cssvar(spacing-3);
    z-index: 10;
}

.textContainer {
    margin-top: $overlay-size;
    padding-top: cssvar(spacing-2);
}

.joinButton {
    margin-top: cssvar(spacing-6);
}
